<template>
  <div
    class="sidebar d-flex flex-column">

    <div class="sidebar-wrapper">
      <div class="logo py-4">
        <nuxt-link
          to="/"
          class="simple-text">
          <div class="logo-img">
            <img
              :src="company ? rootDomain + company + '_menu.png' : rootDomain + 'logo_unidade.png'"
              alt="">
          </div>
        </nuxt-link>
      </div>
      <b-button
        :to="{ name: 'index' }"
        variant="danger"
        block
      >
        <div class="d-flex exit-class">
          <i
            style="font-size: 16pt"
            class="align-self-center mr-2 fa fa-arrow-circle-o-left"/>
          <span>Sair da Sala de Aula</span>
        </div>
      </b-button>
      <ul class="nav flex-column">
        <h5
          id="course-name"
          class="text-center"
        >
          <strong>{{ courseName }}</strong>
        </h5>
        <div
          role="tablist"
          class="px-2 sidebar-container">
          <list-item
            v-for="(node, index) in syllabus.nodes"
            :key="index"
            :accordion="'course'"
            :branch="node"
            :active="activeLesson"/>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import listItem from './listItem'
export default {
  components: {
    listItem
  },
  props: {
    syllabus: { type: Object, default: null},
    courseName: { type: String, default: ''},
    activeLesson: { type: Object, default: null}
  },
  computed: {
    company () {
      return process.env.company;
    },
    rootDomain () {
      return process.env.rootDomain;
    }
  },
}
</script>

<style>
.vb > .vb-dragger {
    z-index: 5;
    width: 12px;
    right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0,0,0,0);
    transform: rotate3d(0,0,0,0);
    -webkit-transition:
        background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
    transition:
        background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
    background-color: rgba(48, 121, 244,.1);
    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244,.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color: rgba(48, 121, 244,.5);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244,.5);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244,.5);
}
</style>
