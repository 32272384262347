<template>
  <b-container 
    fluid
    class="d-flex enroll-layout">
    <b-container class="enroll-form my-5 p-4 bg-white rounded">
      <nuxt class="d-flex flex-column aling-items-center"/>
    </b-container>
  </b-container>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.enroll-layout {
  background: rgb(2,0,36);
  background: linear-gradient(156deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 13%, rgba(0,212,255,1) 100%);
}
</style>

