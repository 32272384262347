// Estado geral da aplicação
const state = () => {
  return {
    view: 'media',
    // Representa os dados da listagem de mídias
    dataset: {
      // Mídias selecionadas
      selecteds: []
    }
  }
}

const getters = {
  getSelecteds: function (state) {
    return state.dataset.selecteds
  },
  getDataset: function (state) {
    return state.dataset
  }
}

const mutations = {
  setSelecteds: function (state, selecteds) {
    state.dataset.selecteds = selecteds
  },
  addSelected: function (state, selected) {
    state.dataset.selecteds.push(selected)
  },
  removeSelected: function (state, key) {
    state.dataset.selecteds.splice(key, 1)
  },
  resetState: function (state) {
    state.dataset = {
      // Mídias listadas
      items: [],
      // Mídias selecionadas
      selecteds: []
    }
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
