import { axios } from '@/plugins/axios'

export default function ({ store }) {

}
export class Config {
  static getDefaultConfigs (defaultOptions) {
    return axios.get('/auth/get-default-options', {params: { 'default_options': defaultOptions }})
  }
}
