import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7641b202 = () => interopDefault(import('../pages/anotacoes.vue' /* webpackChunkName: "pages/anotacoes" */))
const _538c3242 = () => interopDefault(import('../pages/anotacoes/index.vue' /* webpackChunkName: "pages/anotacoes/index" */))
const _ad425ee4 = () => interopDefault(import('../pages/anotacoes/_enrollmentId.vue' /* webpackChunkName: "pages/anotacoes/_enrollmentId" */))
const _6f2d719d = () => interopDefault(import('../pages/avisos.vue' /* webpackChunkName: "pages/avisos" */))
const _82d8e340 = () => interopDefault(import('../pages/avisos/index.vue' /* webpackChunkName: "pages/avisos/index" */))
const _14dfa13a = () => interopDefault(import('../pages/avisos/_alertId.vue' /* webpackChunkName: "pages/avisos/_alertId" */))
const _45bfc223 = () => interopDefault(import('../pages/biblioteca/index.vue' /* webpackChunkName: "pages/biblioteca/index" */))
const _1bbcdf17 = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _48cbd79a = () => interopDefault(import('../pages/exerciciosrespondidos.vue' /* webpackChunkName: "pages/exerciciosrespondidos" */))
const _f2ca7ac6 = () => interopDefault(import('../pages/exerciciosrespondidos/index.vue' /* webpackChunkName: "pages/exerciciosrespondidos/index" */))
const _2130d5e9 = () => interopDefault(import('../pages/exerciciosrespondidos/_enrollmentId.vue' /* webpackChunkName: "pages/exerciciosrespondidos/_enrollmentId" */))
const _3eec7ae9 = () => interopDefault(import('../pages/exerciciosrespondidos/_enrollmentId/exercicios/_lessonId.vue' /* webpackChunkName: "pages/exerciciosrespondidos/_enrollmentId/exercicios/_lessonId" */))
const _b28e7e92 = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _73fa8202 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1ffb59d2 = () => interopDefault(import('../pages/matricula/index.vue' /* webpackChunkName: "pages/matricula/index" */))
const _f0d6d6de = () => interopDefault(import('../pages/matricular/index.vue' /* webpackChunkName: "pages/matricular/index" */))
const _3cc7ac0c = () => interopDefault(import('../pages/matricular-sucesso.vue' /* webpackChunkName: "pages/matricular-sucesso" */))
const _71cca14e = () => interopDefault(import('../pages/mensalidades.vue' /* webpackChunkName: "pages/mensalidades" */))
const _33fb155e = () => interopDefault(import('../pages/mensalidades/index.vue' /* webpackChunkName: "pages/mensalidades/index" */))
const _0236d6c6 = () => interopDefault(import('../pages/mensalidades/_enrollmentId.vue' /* webpackChunkName: "pages/mensalidades/_enrollmentId" */))
const _6275c538 = () => interopDefault(import('../pages/meus-cursos.vue' /* webpackChunkName: "pages/meus-cursos" */))
const _de47604a = () => interopDefault(import('../pages/notas.vue' /* webpackChunkName: "pages/notas" */))
const _463373c4 = () => interopDefault(import('../pages/notas/index.vue' /* webpackChunkName: "pages/notas/index" */))
const _90756f2c = () => interopDefault(import('../pages/notas/_enrollmentId.vue' /* webpackChunkName: "pages/notas/_enrollmentId" */))
const _4d5476bf = () => interopDefault(import('../pages/pagamento/index.vue' /* webpackChunkName: "pages/pagamento/index" */))
const _3f1da348 = () => interopDefault(import('../pages/primeiro-acesso.vue' /* webpackChunkName: "pages/primeiro-acesso" */))
const _4262a622 = () => interopDefault(import('../pages/prova-virtual/index.vue' /* webpackChunkName: "pages/prova-virtual/index" */))
const _647f3cb5 = () => interopDefault(import('../pages/provas.vue' /* webpackChunkName: "pages/provas" */))
const _39b6a778 = () => interopDefault(import('../pages/provas/index.vue' /* webpackChunkName: "pages/provas/index" */))
const _52b3afc4 = () => interopDefault(import('../pages/provas/_enrollmentId.vue' /* webpackChunkName: "pages/provas/_enrollmentId" */))
const _37065347 = () => interopDefault(import('../pages/provas/_enrollmentId/index.vue' /* webpackChunkName: "pages/provas/_enrollmentId/index" */))
const _2bd6f323 = () => interopDefault(import('../pages/provas/_enrollmentId/_schedulingId.vue' /* webpackChunkName: "pages/provas/_enrollmentId/_schedulingId" */))
const _1fbffd6c = () => interopDefault(import('../pages/saladeaula/index.vue' /* webpackChunkName: "pages/saladeaula/index" */))
const _6227aeca = () => interopDefault(import('../pages/aluno/perfil.vue' /* webpackChunkName: "pages/aluno/perfil" */))
const _2d6aba65 = () => interopDefault(import('../pages/aluno/perfil/contato.vue' /* webpackChunkName: "pages/aluno/perfil/contato" */))
const _51636121 = () => interopDefault(import('../pages/aluno/perfil/informacoes-pessoais.vue' /* webpackChunkName: "pages/aluno/perfil/informacoes-pessoais" */))
const _7cf10cfa = () => interopDefault(import('../pages/aluno/perfil/redefinir-senha.vue' /* webpackChunkName: "pages/aluno/perfil/redefinir-senha" */))
const _c16cb626 = () => interopDefault(import('../pages/biblioteca/curso.vue' /* webpackChunkName: "pages/biblioteca/curso" */))
const _20a31a6e = () => interopDefault(import('../pages/biblioteca/curso/_cursoId/_page.vue' /* webpackChunkName: "pages/biblioteca/curso/_cursoId/_page" */))
const _26c65408 = () => interopDefault(import('../pages/biblioteca/labs/index.vue' /* webpackChunkName: "pages/biblioteca/labs/index" */))
const _770c481e = () => interopDefault(import('../pages/matricula/pre-cadastro/index.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/index" */))
const _828d7d22 = () => interopDefault(import('../pages/matricula/pre-cadastro/confirmacao.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/confirmacao" */))
const _72fe89bb = () => interopDefault(import('../pages/matricula/pre-cadastro/email.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/email" */))
const _1974183f = () => interopDefault(import('../pages/matricula/pre-cadastro/finalizar-cadastro.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/finalizar-cadastro" */))
const _e3bd01f0 = () => interopDefault(import('../pages/matricula/pre-cadastro/login.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/login" */))
const _860b9af0 = () => interopDefault(import('../pages/biblioteca/digital/_page.vue' /* webpackChunkName: "pages/biblioteca/digital/_page" */))
const _4a29b27a = () => interopDefault(import('../pages/biblioteca/labs/_labId.vue' /* webpackChunkName: "pages/biblioteca/labs/_labId" */))
const _00b90ea7 = () => interopDefault(import('../pages/biblioteca/publicacao/_tipo/_id.vue' /* webpackChunkName: "pages/biblioteca/publicacao/_tipo/_id" */))
const _c297f83e = () => interopDefault(import('../pages/eventos/_eventId.vue' /* webpackChunkName: "pages/eventos/_eventId" */))
const _6e4f423a = () => interopDefault(import('../pages/matricula/_courseId.vue' /* webpackChunkName: "pages/matricula/_courseId" */))
const _bb689586 = () => interopDefault(import('../pages/matricula/_courseId/index.vue' /* webpackChunkName: "pages/matricula/_courseId/index" */))
const _43f9ba8a = () => interopDefault(import('../pages/matricula/_courseId/_teachingUnit.vue' /* webpackChunkName: "pages/matricula/_courseId/_teachingUnit" */))
const _48b4295e = () => interopDefault(import('../pages/matricula/_courseId/_teachingUnit/informacoes.vue' /* webpackChunkName: "pages/matricula/_courseId/_teachingUnit/informacoes" */))
const _71bde637 = () => interopDefault(import('../pages/matricular/_courseId/index.vue' /* webpackChunkName: "pages/matricular/_courseId/index" */))
const _23e56fea = () => interopDefault(import('../pages/pagamento/_enrollmentId.vue' /* webpackChunkName: "pages/pagamento/_enrollmentId" */))
const _3c8e4cc0 = () => interopDefault(import('../pages/recuperar-senha/_message.vue' /* webpackChunkName: "pages/recuperar-senha/_message" */))
const _d1483c8a = () => interopDefault(import('../pages/resetar-senha/_token.vue' /* webpackChunkName: "pages/resetar-senha/_token" */))
const _0a51d0d4 = () => interopDefault(import('../pages/saladeaula/_enrollmentId.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId" */))
const _3d3176b5 = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/index.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/index" */))
const _4307ab96 = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/_lessonId.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/_lessonId" */))
const _42a91610 = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/_lessonId/index.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/_lessonId/index" */))
const _b82fdeac = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/_lessonId/exerciciosrespondidos.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/_lessonId/exerciciosrespondidos" */))
const _2f49e3fe = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/_lessonId/_file.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/_lessonId/_file" */))
const _21aee5d2 = () => interopDefault(import('../pages/prova-virtual/_examRequestId/finished.vue' /* webpackChunkName: "pages/prova-virtual/_examRequestId/finished" */))
const _5788300e = () => interopDefault(import('../pages/prova-virtual/_examRequestId/info.vue' /* webpackChunkName: "pages/prova-virtual/_examRequestId/info" */))
const _586b619c = () => interopDefault(import('../pages/prova-virtual/_examRequestId/start.vue' /* webpackChunkName: "pages/prova-virtual/_examRequestId/start" */))
const _cb04b1fa = () => interopDefault(import('../pages/matricular/_courseId/_unitId.vue' /* webpackChunkName: "pages/matricular/_courseId/_unitId" */))
const _702d40ca = () => interopDefault(import('../pages/matricular/_courseId/_unitId/cadastrar.vue' /* webpackChunkName: "pages/matricular/_courseId/_unitId/cadastrar" */))
const _7e335d2b = () => interopDefault(import('../pages/matricular/_courseId/_unitId/identificacao.vue' /* webpackChunkName: "pages/matricular/_courseId/_unitId/identificacao" */))
const _601b719a = () => interopDefault(import('../pages/matricular/_courseId/_unitId/pagamento.vue' /* webpackChunkName: "pages/matricular/_courseId/_unitId/pagamento" */))
const _0749c830 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/anotacoes",
    component: _7641b202,
    children: [{
      path: "",
      component: _538c3242,
      name: "anotacoes"
    }, {
      path: ":enrollmentId",
      component: _ad425ee4,
      name: "anotacoes-enrollmentId"
    }]
  }, {
    path: "/avisos",
    component: _6f2d719d,
    children: [{
      path: "",
      component: _82d8e340,
      name: "avisos"
    }, {
      path: ":alertId",
      component: _14dfa13a,
      name: "avisos-alertId"
    }]
  }, {
    path: "/biblioteca",
    component: _45bfc223,
    name: "biblioteca"
  }, {
    path: "/eventos",
    component: _1bbcdf17,
    name: "eventos"
  }, {
    path: "/exerciciosrespondidos",
    component: _48cbd79a,
    children: [{
      path: "",
      component: _f2ca7ac6,
      name: "exerciciosrespondidos"
    }, {
      path: ":enrollmentId",
      component: _2130d5e9,
      name: "exerciciosrespondidos-enrollmentId",
      children: [{
        path: "exercicios/:lessonId?",
        component: _3eec7ae9,
        name: "exerciciosrespondidos-enrollmentId-exercicios-lessonId"
      }]
    }]
  }, {
    path: "/forum",
    component: _b28e7e92,
    name: "forum"
  }, {
    path: "/login",
    component: _73fa8202,
    name: "login"
  }, {
    path: "/matricula",
    component: _1ffb59d2,
    name: "matricula"
  }, {
    path: "/matricular",
    component: _f0d6d6de,
    name: "matricular"
  }, {
    path: "/matricular-sucesso",
    component: _3cc7ac0c,
    name: "matricular-sucesso"
  }, {
    path: "/mensalidades",
    component: _71cca14e,
    children: [{
      path: "",
      component: _33fb155e,
      name: "mensalidades"
    }, {
      path: ":enrollmentId",
      component: _0236d6c6,
      name: "mensalidades-enrollmentId"
    }]
  }, {
    path: "/meus-cursos",
    component: _6275c538,
    name: "meus-cursos"
  }, {
    path: "/notas",
    component: _de47604a,
    children: [{
      path: "",
      component: _463373c4,
      name: "notas"
    }, {
      path: ":enrollmentId",
      component: _90756f2c,
      name: "notas-enrollmentId"
    }]
  }, {
    path: "/pagamento",
    component: _4d5476bf,
    name: "pagamento"
  }, {
    path: "/primeiro-acesso",
    component: _3f1da348,
    name: "primeiro-acesso"
  }, {
    path: "/prova-virtual",
    component: _4262a622,
    name: "prova-virtual"
  }, {
    path: "/provas",
    component: _647f3cb5,
    children: [{
      path: "",
      component: _39b6a778,
      name: "provas"
    }, {
      path: ":enrollmentId",
      component: _52b3afc4,
      children: [{
        path: "",
        component: _37065347,
        name: "provas-enrollmentId"
      }, {
        path: ":schedulingId",
        component: _2bd6f323,
        name: "provas-enrollmentId-schedulingId"
      }]
    }]
  }, {
    path: "/saladeaula",
    component: _1fbffd6c,
    name: "saladeaula"
  }, {
    path: "/aluno/perfil",
    component: _6227aeca,
    name: "aluno-perfil",
    children: [{
      path: "contato",
      component: _2d6aba65,
      name: "aluno-perfil-contato"
    }, {
      path: "informacoes-pessoais",
      component: _51636121,
      name: "aluno-perfil-informacoes-pessoais"
    }, {
      path: "redefinir-senha",
      component: _7cf10cfa,
      name: "aluno-perfil-redefinir-senha"
    }]
  }, {
    path: "/biblioteca/curso",
    component: _c16cb626,
    name: "biblioteca-curso",
    children: [{
      path: ":cursoId?/:page?",
      component: _20a31a6e,
      name: "biblioteca-curso-cursoId-page"
    }]
  }, {
    path: "/biblioteca/labs",
    component: _26c65408,
    name: "biblioteca-labs"
  }, {
    path: "/matricula/pre-cadastro",
    component: _770c481e,
    name: "matricula-pre-cadastro"
  }, {
    path: "/matricula/pre-cadastro/confirmacao",
    component: _828d7d22,
    name: "matricula-pre-cadastro-confirmacao"
  }, {
    path: "/matricula/pre-cadastro/email",
    component: _72fe89bb,
    name: "matricula-pre-cadastro-email"
  }, {
    path: "/matricula/pre-cadastro/finalizar-cadastro",
    component: _1974183f,
    name: "matricula-pre-cadastro-finalizar-cadastro"
  }, {
    path: "/matricula/pre-cadastro/login",
    component: _e3bd01f0,
    name: "matricula-pre-cadastro-login"
  }, {
    path: "/biblioteca/digital/:page?",
    component: _860b9af0,
    name: "biblioteca-digital-page"
  }, {
    path: "/biblioteca/labs/:labId",
    component: _4a29b27a,
    name: "biblioteca-labs-labId"
  }, {
    path: "/biblioteca/publicacao/:tipo?/:id?",
    component: _00b90ea7,
    name: "biblioteca-publicacao-tipo-id"
  }, {
    path: "/eventos/:eventId",
    component: _c297f83e,
    name: "eventos-eventId"
  }, {
    path: "/matricula/:courseId",
    component: _6e4f423a,
    children: [{
      path: "",
      component: _bb689586,
      name: "matricula-courseId"
    }, {
      path: ":teachingUnit",
      component: _43f9ba8a,
      name: "matricula-courseId-teachingUnit",
      children: [{
        path: "informacoes",
        component: _48b4295e,
        name: "matricula-courseId-teachingUnit-informacoes"
      }]
    }]
  }, {
    path: "/matricular/:courseId",
    component: _71bde637,
    name: "matricular-courseId"
  }, {
    path: "/pagamento/:enrollmentId",
    component: _23e56fea,
    name: "pagamento-enrollmentId"
  }, {
    path: "/recuperar-senha/:message?",
    component: _3c8e4cc0,
    name: "recuperar-senha-message"
  }, {
    path: "/resetar-senha/:token?",
    component: _d1483c8a,
    name: "resetar-senha-token"
  }, {
    path: "/saladeaula/:enrollmentId",
    component: _0a51d0d4,
    name: "saladeaula-enrollmentId",
    children: [{
      path: "aula",
      component: _3d3176b5,
      name: "saladeaula-enrollmentId-aula"
    }, {
      path: "aula/:lessonId",
      component: _4307ab96,
      children: [{
        path: "",
        component: _42a91610,
        name: "saladeaula-enrollmentId-aula-lessonId"
      }, {
        path: "exerciciosrespondidos",
        component: _b82fdeac,
        name: "saladeaula-enrollmentId-aula-lessonId-exerciciosrespondidos"
      }, {
        path: ":file",
        component: _2f49e3fe,
        name: "saladeaula-enrollmentId-aula-lessonId-file"
      }]
    }]
  }, {
    path: "/prova-virtual/:examRequestId?/finished",
    component: _21aee5d2,
    name: "prova-virtual-examRequestId-finished"
  }, {
    path: "/prova-virtual/:examRequestId?/info",
    component: _5788300e,
    name: "prova-virtual-examRequestId-info"
  }, {
    path: "/prova-virtual/:examRequestId?/start",
    component: _586b619c,
    name: "prova-virtual-examRequestId-start"
  }, {
    path: "/matricular/:courseId/:unitId",
    component: _cb04b1fa,
    name: "matricular-courseId-unitId",
    children: [{
      path: "cadastrar",
      component: _702d40ca,
      name: "matricular-courseId-unitId-cadastrar"
    }, {
      path: "identificacao",
      component: _7e335d2b,
      name: "matricular-courseId-unitId-identificacao"
    }, {
      path: "pagamento",
      component: _601b719a,
      name: "matricular-courseId-unitId-pagamento"
    }]
  }, {
    path: "/",
    component: _0749c830,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
