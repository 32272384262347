import { Utils } from '@/services/utils'

//window.popStateDetected = false
/*window.addEventListener('popstate', () => {
  window.popStateDetected = true
})*/

export default ({app, route, from, redirect, store}) => {
  store.commit('app/toggleSidebar', false)

  const scrollToTop = route.meta.reduce((scrollToTop, meta) => typeof meta.scrollToTop === 'undefined' ? true : meta.scrollToTop, true)
  if (scrollToTop) {
    Utils.scrollToTop()
  }

  /*let parsedMeta = []

  route.meta.forEach(rm => rm.ignore || parsedMeta.push(rm));

  // Redireciona o usuário para outra rota ao apertar back e voltar para certas páginas
  // Em páginas com paramêtros obrigatórios é necessário marcar o meta do arquivo index.vue
  // (necessário devido as caracteristicas do nuxt) como ignored (exemplo no arquivo /pages/anotacoes/index.vue)
  if (window.popStateDetected && (parsedMeta.length === 1 || from.name.indexOf(route.name) === -1) && parsedMeta[0].backTo) {
    redirect({name: parsedMeta[0].backTo, replace: true})
  }

  window.popStateDetected = false*/
}
