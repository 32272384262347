import { axios } from '@/plugins/axios'

export class Jwt {
  // Chamada do backend para recuperar um token de autenticação
  static accessToken (login, password, platform) {
    return axios.post('/auth/login', { login, password, platform})
  }

  // Chamada do backend para atualizar um token de autenticação
  static refreshToken () {
    return axios.post('/auth/refresh')
  }

  // Chamada do backend para desautenticar, fazer logout, um usuário
  static unauthenticate () {
    return axios.post('/auth/logout')
  }

  // Chamada do backend para enviar um email de redefinição de senha
  static resetPassword (login) {
    return axios.post('/auth/reset-password', { login })
  }

  // Chamada do backend para salvar uma nova senha de um usuário
  static saveNewPassword (token, password, passwordConfirmation) {
    return axios.post('/auth/save-new-password', {
      'remember_token': token,
      password,
      'password_confirmation': passwordConfirmation
    })
  }

  static changePassword (currentPassword, newPassword, newPasswordConfirmation) {
    return axios.patch('/auth/change-password', {
      currentPassword,
      newPassword,
      newPassword_confirmation: newPasswordConfirmation
    })
  }
}
