export class Utils {
  /**
   * Faz o Scroll to top em qualquer layout (default ou sala de aula). Além disso tbm
   * faz o fallback para navegadores que não suportam o método element.scroll().
   */
  static scrollToTop (disableAnimation) {
    const el = document.querySelector('.content, .classroom-content')
    if (el) {
      this.scrollToTopFallback(el, disableAnimation)
    }
    this.scrollToTopFallback(document.documentElement || document.body, disableAnimation)
  }

  static scrollSidebarToTop(disableAnimation) {
    const el = document.querySelector('.sidebar-wrapper')
    if (el) {
      this.scrollToTopFallback(el, disableAnimation)
    }
    this.scrollToTopFallback(document.documentElement || document.body, disableAnimation)
  }

  static scrollToTopFallback(el, disableAnimation) {
    if (typeof el.scroll === 'function') {
      let options = {top: 0}
      if(!disableAnimation) {
        options.behavior= 'smooth'
      }
      el.scroll(options)
    } else {
      el.scrollTop = 0
    }
  }

  static formataValorCentavos(valor) {
    return parseFloat(valor).toFixed(2) * 100;
  }
}
