import Vue from 'vue'

Vue.use({
  install: function (Vue, options) {

    Vue.mixin({
      mounted() {
        if (this.$options.tutorial !== undefined) {
          this.$store.commit('app/setPageTutorial', this.$options.tutorial)
          setTimeout(() => {
            let completedTutorials = this.$store.getters['app/completedTutorials']
            if (completedTutorials === null || completedTutorials[this.$options.tutorial] === undefined || (!completedTutorials[this.$options.tutorial].finished)) {
              import('@/common/tutorials/' + this.$options.tutorial)
              .then(module => {
                const tutorial = new module.default(this.$store, this.$options.tutorial)
                tutorial.start()
              })
            }
          }, 500)
        }
      }
    })
  }
})
