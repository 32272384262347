export default {
  osiris: {
    frameTopColor: '#3d8ebb'
  },
  horus: {
    frameTopColor: '#ff9900'
  },
  seth: {
    frameTopColor: '#8bc34a'
  }
}
