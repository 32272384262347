let localStorage = window.localStorage

let initialState = {
  person: null,
  course: null,
  enrollment: null,
  teachingUnit: null,
  teachingPlatform: null
}

const state = () => {
  return initialState
}

const getters = {
  person: function (state) {
    return state.person
  },
  course: function (state) {
    return state.course
  },
  enrollment: function (state) {
    return state.enrollment
  },
  teachingUnit: function (state) {
    return state.teachingUnit
  },
  teachingPlatform: function (state) {
    return state.teachingPlatform
  }
}

const mutations = {
  setPerson(state, person) {
    state.person = person
    localStorage.setItem('nt-person', JSON.stringify(state))
  },
  setCourse(state, course) {
    state.course = course
    localStorage.setItem('nt-course', JSON.stringify(state))
  },
  setEnrollment(state, course) {
    state.enrollment = course
    localStorage.setItem('nt-enrollment', JSON.stringify(state))
  },
  setTeachingUnit (state, teachingUnit) {
    state.teachingUnit = teachingUnit
    localStorage.setItem('nt-teaching-unit', JSON.stringify(state))
  },
  setTeachingPlatform (state, teachingPlatform) {
    state.teachingPlatform = teachingPlatform
    localStorage.setItem('nt-teaching-platform', JSON.stringify(state))
  },
  loadFromLocalStorage(state, localStorageState) {
    Object.assign(state, localStorageState)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
