<template>
  <footer class="d-flex align-items-center">
    <div>
      <a
        href=""
        target="_blank">&copy; <strong>Grupo NovaescolaBrasil</strong> {{ new Date().getFullYear() }}</a>
    </div>
  </footer>
</template>

<script>
  export default {

  }
</script>

<style scoped>

</style>
