/**
 * Inclusão do Plugin de tradução de libras, para acessibilidade surdos.
 */
const isMobile = window.innerWidth < 991
if(!isMobile) {
  let html = document.createElement('div')
  html.classList.add('enabled')
  html.setAttribute('vw', '')
  html.setAttribute('style', 'margin-top: 0; top: 200px; background-color: #ffffff;')
  html.innerHTML = '<div class="drag-icon draggable"><icon class="fa fa-arrows" title="Arraste para cima ou para baixo."></icon></div><div vw-access-button class="active"></div><div vw-plugin-wrapper><div class="vw-plugin-top-wrapper"></div></div>'
  
  let script = document.createElement('script')
  
  script.onload = function () {
    new window.VLibras.Widget('https://vlibras.gov.br/app')
  };
  script.src = 'https://vlibras.gov.br/app/vlibras-plugin.js'
  
  document.body.appendChild(html)
  document.body.appendChild(script)
  
  let Draggable = function (elemento) {
    let that = this;
    this.elemento = elemento;
    // this.posX = 0;
    this.posY = 0
    this.top = 200
    // this.left = elemento.offsetLeft;
    this.refMouseUp = function (event) {
      that.onMouseUp(event);
    }
  
    this.refMouseMove = function (event) {
      that.onMouseMove(event);
    }
  
    this.elemento.addEventListener("mousedown", function (event) {
      that.onMouseDown(event);
    });
  }
  
  Draggable.prototype.onMouseDown = function (event) {
    // this.posX = event.x;
    this.posY = event.y;
  
    this.elemento.parentElement.classList.add("dragging");
    window.addEventListener("mousemove", this.refMouseMove);
    window.addEventListener("mouseup", this.refMouseUp);
  }
  
  Draggable.prototype.onMouseMove = function (event) {
    // let diffX = event.x - this.posX;
    let diffY = 0;
    let maxY = (document.body.clientHeight - 150)
    let minY = 0
    diffY = event.y - this.posY
    let top = this.top + diffY
    top = top < maxY ? top : maxY
    top = top > minY ? top : minY
  
    this.elemento.parentElement.style.top = (top) + "px"
    // this.elemento.style.left = (this.left + diffX) + "px";
  }
  
  Draggable.prototype.onMouseUp = function (event) {
    this.top = parseInt(this.elemento.parentElement.style.top.replace(/\D/g, '')) || 0;
    // this.left = parseInt(this.elemento.parentElement.style.left.replace(/\D/g, '')) || 0;
    this.elemento.parentElement.classList.remove("dragging");
    window.removeEventListener("mousemove", this.refMouseMove); 
    window.removeEventListener("mouseup", this.refMouseUp);  
  }
  
  new Draggable(document.querySelector('.draggable'));
  
  // let draggables = document.querySelectorAll(".draggable");
  // [].forEach.call(draggables, function (draggable, indice) {
  //   new Draggable(draggable);
  // });
}