<template>
  <div class="__nuxt-error-page">
    <div class="error">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="90"
        height="90"
        fill="#DBE1EC"
        viewBox="0 0 48 48"><path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"/></svg>

      <div class="title">{{ message }}</div>
      <p
        v-if="statusCode === 404"
        class="description">
        <nuxt-link
          :to="{ name:'index' }"
          class="error-link">Retornar a página principal</nuxt-link>
      </p>

      <p
        v-else
        class="description">Ocorreu um erro enquanto a página era carregada. Por favor verifique sua conexão.</p>


        <!-- <div class="logo">
        <a
          href="http://novaescolabrasil.com.br/novatech/"
          target="_blank"
          rel="noopener">Grupo Novaescolabrasil</a>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  layout: 'error-layout',
  head () {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
        }
      ]
    }
  },
  // Only on debug mode
  data () {
    return {
      mounted: false
    }
  },
  computed: {
    statusCode () {
      return (this.error && this.error.statusCode) || 500
    },
    message () {
      return (this.error.statusCode === 404) ? 'Página não encontrada' : 'Erro'
    }
  },
  watch: {
    error (newErr) {
      if (newErr) {
        console.error(newErr)
      }
    }
  },
  mounted () {
    this.mounted = true
  },
  created () {
    console.error(this.error)
  },
}
</script>

<style>
.__nuxt-error-page {
  padding: 1rem;
  background: #F7F8FB;
  color: #47494E;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  font-weight: 100 !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.__nuxt-error-page .error {
  max-width: 450px;
}
.__nuxt-error-page .title {
  font-size: 1.5rem;
  margin-top: 15px;
  color: #47494E;
  margin-bottom: 8px;
}
.__nuxt-error-page .description {
  color: #7F828B;
  line-height: 21px;
  margin-bottom: 10px;
}
.__nuxt-error-page a {
  color: #7F828B !important;
  text-decoration: none;
}
.__nuxt-error-page .logo {
  position: fixed;
  left: 12px;
  bottom: 12px;
}
</style>
