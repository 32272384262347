import { axios } from '@/plugins/axios'

export class User {
  /**
   * Chamada do backend que recupera o usuário que está logado
   */
  static get () {
    return axios.get('/auth/me')
  }

  /**
   * Chamada do backend que recupera um usuário de acordo com
   * um remember_token
   */
  static getByToken (token) {
    return axios.get('/auth/recover-user-by-token', { params: { remember_token: token } })
  }

  /**
   * Chamada do backend que recupera o usuário logado com seus
   * dados de aluno
   */
  static getWithStudent (query) {
    let requestUrl = '/auth/get-me-with-student'

    if (query) {
      requestUrl += '?'
      Object.keys(query).forEach(index => {
        requestUrl = requestUrl + index + '=' + query[index]
        requestUrl += '&'
      })
    }

    return axios.get(requestUrl)
  }

  /**
   * Chamada HTTP que retorna os dados da pessoa relacionada ao usuário
   *
   * @param {string} query
   */
  static person (scope) {
    let requestUrl = '/user/me'

    if (scope) {
      requestUrl += '?'

      requestUrl = requestUrl + 'scope=' + scope
      // requestUrl += '&'
    }

    return axios.get(requestUrl)
  }

  /**
   * Retorna uma requisição como promessa que carrega os dados
   * necessários para montar o formulário de profile do usuário logado
   * @param {*} params
   */
  static profileUser () {
    return axios.get('/user/profile')
  }

  /**
   * Salva usuário no banco de dados e retorna uma promessa com a resposta do servidor.
   */
  static storeUser (username, email, name, dateOfBirth, gender, password, passwordConfirmation) {
    return axios.post('/user', {
      username,
      email,
      name,
      dateOfBirth,
      gender,
      password,
      password_confirmation: passwordConfirmation
    })
  }

  /**
   * Recebe dados de um usuário como parâmetro e retorna uma
   * requisição como promessa que carrega os dados do usuário
   * a ser editado, bem como os dados necessários para montar o
   * formulário
   * @param {*} params
   */
  static editUser (params) {
    return axios.get('/user/edit', params)
  }

  /**
   * Recebe dados de um usuário como parâmatro e retorna uma
   * requisição como promessa que atualiza este usuário no BD
   * @param {*} params
   */
  static updateUser (params, scope) {
    params = (scope ? { scope, ...params} : params)
    return axios.patch('/user', {...params})
  }

  /**
   * Recebe dados de um usuário como parâmetro e retorna uma requisição
   * como promessa que remove este usuário
   * @param {*} params
   */
  static removeUser (params) {
    return axios.post('/user/destroy', params)
  }

  /**
   * Salva usuário no banco de dados e retorna uma promessa com a resposta do servidor.
   */
  static storeOption (key, value) {
    return axios.post('/user/options', {
      'key': key,
      'value': value
    })
  }
}
