import { Exercise } from '@/services/exercise.js'

const state = () => {
  return {
    exercises: [],
    answers: [],
    midias: {}
  }
}

const getters = {
  getExercises: function (state) {
    return state.exercises
  },
  getAnswers: function (state) {
    return state.answers
  },
  getMidias: function (state) {
    return state.midias
  }
}

const mutations = {
  setExercises (state, exercises) {
    state.exercises = exercises
  },
  setAnswers (state, answers) {
    state.answers = answers
  },
  setMidia (state, midia) {
    state.midias[midia.respostaAlunoId] = midia.data;
  }
}

const actions = {
  async loadExercisesAndAnswers (context, data) {
    context.commit('setExercises', data.exercises);
    context.commit('setAnswers', data.answers);

    for(let id in data.exercises) {
      let url = '';
      let exercise = data.exercises[id];
      let respostaAlunoId = exercise.resposta_aluno_id;
      let respostas = data.answers[respostaAlunoId];

      if(respostas && respostas.length) {
        let respostasDA = respostas.filter((r) => {
          return r.tipo === 'DA'
        });

        let ultimaRespostaComAudio = respostasDA.sort((a, b) => {
          let dateA = new Date(a.created_at);
          let dateB = new Date(b.created_at);
          return dateA.getTime() > dateB.getTime() ? -1 : 1;
        })[0];

        url = ultimaRespostaComAudio ? ultimaRespostaComAudio.dado : '';
        if(url) {
          let res =  await Exercise.getAnswerFile(url);
          if(res && res.data)
            context.commit('setMidia', {respostaAlunoId, data: res.data});
        }
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
