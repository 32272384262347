import { axios } from '@/plugins/axios'
import Vue from 'vue'

(function () {
  /**
   * Função chamada pela custom directive auth-image que recebe como
   * parâmetro as propriedades do DOM que representam o template com a
   * custom directive, realiza uma requisição AJAX para buscar uma imagem
   * no BackEnd e gera o dataimage para o template.
   * Obs: Isto é necessário devido ao sistema não trabalhar com seção, por
   * isso o sistema não consegue tratar requisições que não seja AJAX
   * @param el elemento do template
   * @param binding o que foi passado como parâmetro para a custom directive
   */
  let setImgSrc = function (el, binding, vnode) {
    if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
      let imageUrl = binding.value
      axios({
        method: 'get',
        url: imageUrl,
        responseType: 'arraybuffer'
      })
        .then((response) => {
          let mimeType = response.headers['content-type'].toLowerCase()
          let imgBase64 = Buffer.from(response.data, 'binary').toString('base64')
          let customSrc = (mimeType && imgBase64) ? 'data:' + mimeType + ';base64,' + imgBase64 : ''
          if (el.tagName === 'IMG') {
            el.src = customSrc
          } else {
            vnode.context.customSrc = customSrc
            vnode.context.$emit('setSrc', customSrc)
          }
        })
        .catch(() => {
        })
    }
  }

  // Cria uma nova custom directive
  Vue.directive('auth-image', {
    bind: function (el, binding, vnode) {
      setImgSrc(el, binding, vnode)
    },
    componentUpdated: function (el, binding, vnode) {
      setImgSrc(el, binding, vnode)
    }
  })
})()
